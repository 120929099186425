import * as Sentry from '@sentry/browser'

const dsn =
  'https://7c39ea6bee916daaac14482a31fc9bb0@o4505985012793344.ingest.sentry.io/4506097514250240'

export const globalFlags = {
  isSentryInitialized: false,
}

class SentryService {
  async init({ server }: { server?: boolean }): Promise<void> {
    const client = Sentry.getCurrentHub().getClient()
    // No need to initialize in dev
    if (process.env['NEXT_PUBLIC_APP_ENV'] !== 'prod') {
      return
    }

    // If
    if (client?.getDsn()?.host === dsn || globalFlags.isSentryInitialized) {
      return
    }

    if (server) {
      Sentry.init({
        dsn,
        release: process.env['NEXT_PUBLIC_APP_VERSION'],
        environment: process.env['NEXT_PUBLIC_APP_ENV'],

        // Adjust this value in production, or use tracesSampler for greater control
        tracesSampleRate: 1,

        // Setting this option to true will print useful information to the console while you're setting up Sentry.
        debug: false,
      })
      return
    }

    Sentry.init({
      dsn,
      release: process.env['NEXT_PUBLIC_APP_VERSION'],
      environment: process.env['NEXT_PUBLIC_APP_ENV'],
      tracesSampleRate: 1,
      replaysOnErrorSampleRate: 1,
      replaysSessionSampleRate: 0.1,

      integrations: [],
      denyUrls: [
        /localhost/,
        /127\.0\.0\.1/,
        /graph\.facebook\.com/i,
        /connect\.facebook\.net\/en_US\/all\.js/i,
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        /extensions\//i,
        /^chrome:\/\//i,
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      ],
      ignoreErrors: [
        // The following list comes from: https://docs.sentry.io/clients/javascript/tips
        'top.GLOBALS',
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'http://tt.epicplay.com',
        "Can't find variable: ZiteReader",
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',
        'fb_xd_fragment',
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        'conduitPage',
        'scpixel is not defined', // Chrome
        "Can't find variable: scpixel", // Safari
        "property 'readyState' of null",
        "evaluating 'g.readyState'",
        'g is null',
      ],
      // debug: true,
    })

    // Lazy load Replay to minimize bundle size
    const { Replay } = await import('@sentry/browser')

    if (client && client.addIntegration) {
      client.addIntegration(
        new Replay({
          // Additional Replay configuration goes in here, for example:
          maskAllText: false,
          blockAllMedia: false,
        }),
      )
    }

    globalFlags.isSentryInitialized = true
  }
}

export const sentryService = new SentryService()
