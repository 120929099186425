import { EnvironmentLocal } from './local.env'

export class EnvironmentTest extends EnvironmentLocal {
  name = 'local'
  prod = false
  dev = false
  local = true
  test = false
}

export const env = new EnvironmentTest()
