import { ChakraProvider } from '@chakra-ui/react'
import { Dialog } from '@goatlab/react-ui'
import { Analytics } from '@vercel/analytics/react'
import { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { DefaultSeo } from '@components/common/default-seo'
import { ManagedModal } from '@components/common/modal/ManagedModal2'
import { backendHook } from '@src/api/trpc'
import { useFirebaseUser } from '@src/hooks/useFirebaseUser'
import { useHtmlDirection } from '@src/hooks/useHtmlDirection'
import { useOnboarding } from '@src/hooks/useOnboarding'
import { useSentry } from '@src/hooks/useSentry'
import { useSetSite } from '@src/hooks/useSetSite'
import { useSetTimezone } from '@src/hooks/useSetTimezone'
import { useUserAccountAndRoles } from '@src/hooks/useUserAccountAndRoles'
import './styles.scss'

const OnboardingWizard = dynamic(() =>
  import('@components/auth/onboardingWizard/OnboardingWizard').then(
    mod => mod.OnboardingWizard,
  ),
)

const Progress = dynamic(
  () => import('@components/ui/PageProgress').then(mod => mod.PageProgress),
  { ssr: false },
)

const Toaster = dynamic(
  () => import('@goatlab/react-ui').then(mod => mod.Toaster),
  { ssr: false },
)

const ManagedDrawer = dynamic(
  () => import('@components/common/drawer/managed-drawer'),
  { ssr: false },
)

const GealiumApp = ({ Component, pageProps }: AppProps) => {
  const router = useRouter()

  useSentry()
  // useFullTailwind()
  useHtmlDirection()
  useFirebaseUser()
  useUserAccountAndRoles()
  useSetSite()
  useSetTimezone()

  const { shouldShowOnboarding, setShouldShowOnboarding } = useOnboarding()

  return (
    <ChakraProvider>
      <Progress />
      <DefaultSeo />
      <Dialog defaultOpen={false} open={shouldShowOnboarding}>
        <OnboardingWizard setDialogOpen={setShouldShowOnboarding} />
      </Dialog>
      <Component {...pageProps} key={router.route} />
      <Toaster />
      <ManagedDrawer />
      <ManagedModal />
      <Analytics />
    </ChakraProvider>
  )
}

export default backendHook.withTRPC(GealiumApp)
