import { useEffect, useState } from 'react'
import { useAuth } from '@components/auth/store/auth.store'

export const useOnboarding = () => {
  const { user, isAuthenticated } = useAuth()
  const [shouldShowOnboarding, setShouldShowOnboarding] = useState(false)

  useEffect(() => {
    if (
      isAuthenticated() &&
      user?.account?.id &&
      !user?.account?.country &&
      !shouldShowOnboarding
    ) {
      setShouldShowOnboarding(true)
    }
  }, [user, isAuthenticated, shouldShowOnboarding])

  return {
    shouldShowOnboarding,
    setShouldShowOnboarding,
  }
}
