import { Environment } from './gealium/prod.env'
import { Apps, AppEnv } from '@src/sodiumConfig'

interface EnvConfiguration {
  [key: string]: {
    local: Environment
    test: Environment
    prod: Environment
    dev: Environment
  }
}

const environments: EnvConfiguration = {
  agrosocial: {
    local: require('./agrosocial/local.env').env,
    test: require('./agrosocial/test.env').env,
    dev: require('./agrosocial/dev.env').env,
    prod: require('./agrosocial/prod.env').env,
  },
  gealium: {
    local: require('./gealium/local.env').env,
    test: require('./gealium/test.env').env,
    dev: require('./gealium/dev.env').env,
    prod: require('./gealium/prod.env').env,
  },
} as const

const appEnv = (process.env.NEXT_PUBLIC_APP_ENV || 'dev') as AppEnv
const appName = (process.env.NEXT_PUBLIC_APP_NAME || 'gealium') as Apps

const env: Environment = environments[appName]?.[appEnv] as Environment

export { env }
