import {FC} from 'react'
import {Modal} from './Modal'
import {useUI} from '@contexts/ui/ui.context'

export const ManagedModal: FC = () => {
  const {displayModal, closeModal, modalView, modalDismissible} = useUI()

  return (
    <Modal
      open={displayModal}
      onClose={closeModal}
      dismissible={modalDismissible}
    >
      {modalView}
    </Modal>
  )
}
