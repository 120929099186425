import clsx from 'clsx'
import { FC, useEffect, useState } from 'react'
import { useScrollHeight } from './hooks/useScrollHeight'

interface ScrollToTopProps {}

export const ScrollToTop: FC<ScrollToTopProps> = () => {
  const { lastScrollY } = useScrollHeight()
  const [shouldDisplay, setShouldDisplay] = useState<boolean>(false)

  useEffect(() => {
    if (
      document.body.scrollTop > 50 ||
      document.documentElement.scrollTop > 50
    ) {
      setShouldDisplay(true)
      return
    }
    setShouldDisplay(false)
  }, [lastScrollY])

  const easeInOutQuad = function (t: number, b: number, c: number, d: number) {
    t /= d / 2
    if (t < 1) return (c / 2) * t * t + b
    t--
    return (-c / 2) * (t * (t - 2) - 1) + b
  }

  function scrollTo(element: any, to = 0, duration = 500) {
    const start = element.scrollTop
    const change = to - start
    const increment = 20
    let currentTime = 0

    const animateScroll = () => {
      currentTime += increment

      const val = easeInOutQuad(currentTime, start, change, duration)

      element.scrollTop = val

      if (currentTime < duration) {
        setTimeout(animateScroll, increment)
      }
    }

    animateScroll()
  }

  return (
    <div
      onClick={() => {
        scrollTo(document.documentElement)
      }}
      className={clsx(
        'back-to-top fixed bottom-8 left-auto right-8 z-[999] h-10 w-10 items-center justify-center rounded-md bg-[#3758F9] text-white shadow-md transition duration-300 ease-in-out hover:bg-[#111928]',
        {
          flex: shouldDisplay,
          hidden: !shouldDisplay,
        }
      )}
    >
      <span className='mt-[6px] h-3 w-3 rotate-45 border-l border-t border-white'></span>
    </div>
  )
}
