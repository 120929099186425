import { decode as atob } from 'base-64'

export const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url?.replace(/-/g, '+').replace(/_/g, '/')

  if (!base64) {
    return ''
  }

  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c: any) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}
