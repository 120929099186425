import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { CartActions, CartState } from './cart.model'
import {
  addItemWithQuantity,
  removeItemOrQuantity,
  removeItem,
  addItemOrQuantity,
  generateFinalState,
  backendCartToState,
} from './cart.utils'
import { backendRequest } from '@api/trpc'
import { postpone } from '@src/utils/postpone'

export const initialCartState: CartState = {
  items: [],
  isEmpty: true,
  totalItems: 0,
  totalUniqueItems: 0,
  total: 0,
  meta: null,
}

export const useCartStore = create<CartState & CartActions>()(
  persist(
    (set, get) => ({
      ...initialCartState,
      getCart() {
        postpone(async () => {
          const response = await backendRequest.cart.getCart.query()
          set((state) => backendCartToState(state, response))
        })
      },
      addItemToCart(item, quantity) {
        set((state) => {
          const items = addItemWithQuantity(state.items, item, quantity)
          return generateFinalState(state, items)
        })

        postpone(async () => {
          const originalId = item.id.split('.')[0]
          const response = await backendRequest.cart.addItem.mutate({
            itemId: Number(originalId),
            quantity,
            variation: item.variation || null,
            cartItemData: item.cartItemData,
          })

          set((state) => backendCartToState(state, response))
        })
      },
      removeItemFromCart(id) {
        const item = get().items.find((i) => i.id === id)

        set((state) => {
          const items = removeItem(state.items, id)
          return generateFinalState(state, items)
        })

        postpone(async () => {
          if (item?.itemKey) {
            const response = await backendRequest.cart.removeItem.mutate({
              itemKey: item?.itemKey,
            })

            set((state) => backendCartToState(state, response))
          }
        })
      },
      increaseItemQuantity(id, quantity) {
        const state = get()
        const item = get().items.find((i) => i.id === id)
        const items = addItemOrQuantity(state.items, id, quantity)
        const updatedItem = items.find((updatedItem) => updatedItem.id === id)
        set(generateFinalState(state, items))

        postpone(async () => {
          if (item?.itemKey) {
            // We need to make sure that we cancel the previous call if it
            // is still active, it could happend that one resolves
            // after than the other one (press the + button fast enough)
            const response = await backendRequest.cart.updateItem.mutate({
              itemKey: item?.itemKey,
              quantity: updatedItem?.quantity || 0,
            })
            set((state) => backendCartToState(state, response))
          }
        })
      },
      decreaseItemQuantity(id, quantity) {
        const state = get()
        const item = get().items.find((i) => i.id === id)
        const items = removeItemOrQuantity(state.items, id, quantity)
        const updatedItem = items.find((updatedItem) => updatedItem.id === id)
        set(generateFinalState(state, items))

        postpone(async () => {
          if (item?.itemKey) {
            // If the item was removed from the cart, we need to
            // remove it from the backend as well
            if (!updatedItem) {
              const response = await backendRequest.cart.removeItem.mutate({
                itemKey: item?.itemKey,
              })
              set((state) => backendCartToState(state, response))
              return
            }
            // We need to make sure that we cancel the previous call if it
            // is still active, it could happend that one resolves
            // after than the other one (press the + button fast enough)
            const response = await backendRequest.cart.updateItem.mutate({
              itemKey: item?.itemKey,
              quantity: updatedItem?.quantity || 0,
            })
            set((state) => backendCartToState(state, response))
          }
        })
      },
      clearCart() {
        set(initialCartState)
        postpone(async () => {
          const response = await backendRequest.cart.clearCart.mutate()
          if (!response) {
            return
          }
          set((state) => {
            return backendCartToState(state, response)
          })
        })
      },
    }),
    {
      name: 'cart',
    }
  )
)
