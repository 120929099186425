import * as path from 'path'
import packageJson from '../package.json'

export interface PackageInfo {
  name: string
  version: string
  description: string
}

export const pkg = packageJson
export const srcPath = path.join(__dirname, '.')
export const rootPath = path.join(srcPath, '../')

export const defaultProductImage =
  'https://assets-dev.gealium.com/uploads/2023/03/15173258/woocommerce-placeholder.png'

export const ownDomains = [
  'gealium.com',
  'dev.gealium.com',
  'localhost',
  'www.gealium.com',
  'dev.gealium.com',
]
