import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useIs } from './useIs'
import { useSite } from '@contexts/site/site.context'
import { ownDomains } from '@src/consts'
import { typesenseService } from '@src/services/typesense/typesense.service'

export const useSetSite = () => {
  const router = useRouter()
  const { setSite } = useSite()
  const { isSpacePage } = useIs()

  useEffect(() => {
    const hostName = window.location.hostname
    const slug = router.query?.space ? String(router.query?.space) : undefined

    if (!slug) {
      setSite({
        isSpace: isSpacePage,
        isExternalDomain: !ownDomains.includes(hostName),
        isOrganization: false,
        domain: hostName,
        slug: '',
        logo: '',
        menuItems: [],
        accountId: '',
        accountName: '',
        landingPageCSS: '',
        landingPageHTML: '',
      })
      return
    }

    // No need to wait for this, it will just set on the state
    // TODO: handle catch
    typesenseService
      .getMainPageBySlug(slug)
      .then(res => {
        console.log({ res })
        setSite({
          isSpace: isSpacePage,
          isExternalDomain: !ownDomains.includes(hostName),
          isOrganization: res?.isOrganization || false,
          domain: hostName,
          slug,
          logo: res?.logo || '',
          menuItems: res?.menuItems || [],
          accountId: res?.isOrganization
            ? res?.accountId
            : res?.firebaseId || '',
          accountName: res?.accountName || '',
          landingPageCSS: res?.landingPageCSS || '',
          landingPageHTML: res?.landingPageHTML || '',
        })
      })
      .catch(err => {
        // Enhanced error handling
        console.error('Error fetching page data:', err)
      })
  }, [router.query?.space, router.asPath])
}
