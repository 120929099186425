import {useState, useEffect} from 'react'

export const useScrollHeight = () => {
  const [lastScrollY, setLastScrollY] = useState(0)
  const [navbarOffset, setNavbarOffset] = useState(0)

  const controlNavbar = () => {
    if (typeof window !== 'undefined') {
      const scrollDiff = window.scrollY - lastScrollY

      let newOffset = navbarOffset - scrollDiff
      newOffset = Math.min(0, Math.max(-56, newOffset))

      setNavbarOffset(newOffset)
      setLastScrollY(window.scrollY)
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', controlNavbar)

      // cleanup function
      return () => {
        window.removeEventListener('scroll', controlNavbar)
      }
    }
    return
  }, [navbarOffset, lastScrollY])

  return {
    navbarOffset,
    lastScrollY,
  }
}
