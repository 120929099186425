import { create } from 'zustand'

export interface AccountContext {
  selectedAccount?: {
    id: string
    name?: string
    imageUrl?: string
    slug?: string
    isOrganization: boolean
  }
}

const initialAccountState: AccountContext = {
  selectedAccount: undefined,
}

export interface AccountActions {
  selectAccount: (account?: AccountContext['selectedAccount']) => void
  setAccountImage: (imageUrl: string) => void
  setAccountSlug: (slug?: string | null) => void
  clearAccount: () => void
}

export const useAccountStore = create<AccountContext & AccountActions>(
  (set) => {
    return {
      ...initialAccountState,
      selectAccount(selectedAccount) {
        set({ selectedAccount })
      },
      setAccountImage(imageUrl) {
        set((state) => {
          if (state.selectedAccount) {
            const newSelectedAccount = { ...state.selectedAccount, imageUrl }

            return {
              selectedAccount: newSelectedAccount,
            }
          }

          return state
        })
      },
      setAccountSlug(slug) {
        if (!slug) {
          return
        }
        set((state) => {
          if (state.selectedAccount) {
            const newSelectedAccount = { ...state.selectedAccount, slug }

            return {
              selectedAccount: newSelectedAccount,
            }
          }

          return state
        })
      },
      clearAccount() {
        set(initialAccountState)
      },
    }
  }
)
