import { ReactNode } from 'react'
import { create } from 'zustand'

export interface UIState {
  displaySidebar: boolean
  displayFilter: boolean
  displayModal: boolean
  displayShop: boolean
  displayCart: boolean
  displaySearch: boolean
  displayHeader: boolean
  displayTemplateSelector: boolean
  modalView: ReactNode
  modalData: any
  modalTitle?: string | null
  modalDismissible: boolean
  drawerView: string | null
  toastText: string
  userAvatar: string
  accountSideBarOpen: boolean
  displayingOnboarding: boolean
  isFullScreenModal?: boolean
  inboxSidebarOpen: boolean
  isAppLoading: boolean
}

const initialUIState: UIState = {
  displaySidebar: false,
  displayFilter: false,
  displayModal: false,
  displayShop: false,
  displayCart: false,
  displaySearch: false,
  displayHeader: true,
  displayTemplateSelector: false,
  modalView: null,
  drawerView: null,
  modalData: null,
  modalTitle: null,
  isFullScreenModal: false,
  modalDismissible: true,
  toastText: '',
  userAvatar: '',
  accountSideBarOpen: true,
  displayingOnboarding: false,
  inboxSidebarOpen: true,
  isAppLoading: false
}

type DrawerViews = 'CART_SIDEBAR' | 'MOBILE_MENU'

export interface UIActions {
  openSideBar: () => void
  closeSideBar: () => void
  openCart: () => void
  closeCart: () => void
  openSearch: () => void
  closeSearch: () => void
  setToastText: (text: string) => void
  openFilter: () => void
  closeFilter: () => void
  openShop: () => void
  closeShop: () => void
  openModal: () => void
  closeModal: () => void
  setDisplayHeader: (display: boolean) => void
  openTemplateSelector: () => void
  closeTemplateSelector: () => void
  setModalView: (view: ReactNode) => void
  setDrawerView: (view: DrawerViews) => void
  setModalData: (data: Record<string, any>) => void
  setModalTitle: (title: string) => void
  setModalDismissible: (dismissible: boolean) => void
  setUserAvatar: (url: string) => void
  setAccountSidebarOpen: (open: boolean) => void
  setDisplayingOnboarding: (open: boolean) => void
  setIsFullScreenModal: (fullScreen: boolean) => void
  setInboxSidebarOpen: (open: boolean) => void
  setAppLoading: (loading: boolean) => void
  Modal: {
    open: () => void
    close: () => void
  }
}

export const useUIStore = create<UIState & UIActions>((set, store) => {
  return {
    ...initialUIState,
    Modal: {
      open() {},
      close() {
        store().closeModal()
      },
    },
    setDisplayHeader(display) {
      set({ displayHeader: display })
    },
    setInboxSidebarOpen(open) {
      set({ inboxSidebarOpen: open })
    },
    setModalTitle(title) {
      set({ modalTitle: title })
    },
    openSideBar() {
      set({ displaySidebar: true })
    },
    closeSideBar() {
      set({ displaySidebar: false })
    },
    openCart() {
      set({ displayCart: true })
    },
    closeCart() {
      set({ displayCart: false })
    },
    openSearch() {
      set({ displaySearch: true })
    },
    setAppLoading(isAppLoading) {
      set({ isAppLoading })
    },
    closeSearch() {
      set({ displaySearch: false })
    },
    openTemplateSelector() {
      set({ displayTemplateSelector: true })
    },
    closeTemplateSelector() {
      set({ displayTemplateSelector: false })
    },
    openFilter() {
      set({ displayFilter: true })
    },
    closeFilter() {
      set({ displayFilter: false })
    },
    openShop() {
      set({ displayShop: true })
    },
    closeShop() {
      set({ displayShop: false })
    },
    openModal() {
      set({ displayModal: true })
    },
    closeModal() {
      set({
        displayModal: false,
        modalDismissible: true,
        modalData: undefined,
        modalTitle: null,
        isFullScreenModal: false,
      })
    },
    setModalDismissible(dismissible) {
      set({ modalDismissible: dismissible })
    },
    setIsFullScreenModal(fullScreen) {
      set({ isFullScreenModal: fullScreen })
    },
    setModalView(modalView) {
      set({ modalView })
    },
    setDrawerView(drawerView) {
      set({ drawerView })
    },
    setModalData(modalData) {
      set({ modalData })
    },
    setToastText(toastText) {
      set({ toastText })
    },
    setUserAvatar(userAvatar) {
      set({ userAvatar })
    },
    setAccountSidebarOpen(open) {
      set({ accountSideBarOpen: open })
    },
    setDisplayingOnboarding(displaying) {
      set({ displayingOnboarding: displaying })
    },
  }
})
