import type {
  FECartItem,
  CartState,
  UpdateItemInput,
  StoreCart,
} from './cart.model'

export function addItemWithQuantity(
  items: FECartItem[],
  item: FECartItem,
  quantity: number,
): FECartItem[] {
  if (quantity <= 0) {
    throw new Error("cartQuantity can't be zero or less than zero")
  }

  const existingItemIndex = items.findIndex(
    existingItem => existingItem.id === item.id,
  )

  if (existingItemIndex > -1) {
    const newItems = [...items]
    newItems[existingItemIndex].quantity! += quantity
    return newItems
  }

  return [...items, { ...item, quantity }]
}

export function removeItemOrQuantity(
  items: FECartItem[],
  id: FECartItem['id'],
  quantity: number,
): FECartItem[] {
  const acc: FECartItem[] = []

  for (const item of items) {
    if (item.id === id) {
      const newQuantity = item.quantity! - quantity

      if (newQuantity > 0) {
        acc.push({ ...item, quantity: newQuantity })
      }
    } else {
      acc.push(item)
    }
  }

  return acc
}

export function addItemOrQuantity(
  items: FECartItem[],
  id: FECartItem['id'],
  quantity: number,
): FECartItem[] {
  const acc: FECartItem[] = []

  for (const item of items) {
    if (item.id === id) {
      const newQuantity = item.quantity! + quantity

      if (newQuantity > 0) {
        acc.push({ ...item, quantity: newQuantity })
      }
    } else {
      acc.push(item)
    }
  }

  return acc
}

// Simple CRUD for Item
export function addItem(items: FECartItem[], item: FECartItem): FECartItem[] {
  return [...items, item]
}

export function getItem(
  items: FECartItem[],
  id: FECartItem['id'],
): FECartItem | undefined {
  return items.find(item => item.id === id)
}

export function updateItem(
  items: FECartItem[],
  id: FECartItem['id'],
  item: UpdateItemInput,
): FECartItem[] {
  return items.map(existingItem =>
    existingItem.id === id ? { ...existingItem, ...item } : existingItem,
  )
}

export function removeItem(
  items: FECartItem[],
  id: FECartItem['id'],
): FECartItem[] {
  return items.filter(existingItem => existingItem.id !== id)
}

export const calculateItemTotals = (items: FECartItem[]): FECartItem[] =>
  items.map(item => ({
    ...item,
    itemTotal: item.price * item.quantity!,
  }))

export const calculateTotal = (items: FECartItem[]): number => {
  return items.reduce((total, item) => total + item.quantity! * item.price, 0)
}

export const calculateTotalItems = (items: FECartItem[]): number => {
  return items.reduce((sum, item) => sum + item.quantity!, 0)
}

export const calculateUniqueItems = (items: FECartItem[]): number => {
  return items.length
}

export const generateFinalState = (
  state: CartState,
  items: FECartItem[],
): CartState => {
  const totalUniqueItems = calculateUniqueItems(items)
  return {
    ...state,
    items: calculateItemTotals(items),
    totalItems: calculateTotalItems(items),
    totalUniqueItems,
    total: calculateTotal(items),
    isEmpty: totalUniqueItems === 0,
  }
}

/**
 * The local state will be updated with the remote state
 * The remote state is what we will later use to
 * generate the checkout information
 *
 * @param state
 * @param coCartCart
 * @returns
 */
export const backendCartToState = (
  state: CartState,
  storeCartState: StoreCart,
): CartState => {
  console.log(storeCartState)
  // Base information from response
  const newCartState: CartState = {
    cartHash: '',
    cartKey: '',
    currency: {
      code: storeCartState.totals.currency_code,
      decimals: storeCartState.totals.currency_minor_unit,
      prefix: storeCartState.totals.currency_prefix,
      suffix: storeCartState.totals.currency_suffix,
      symbol: storeCartState.totals.currency_symbol,
      thousandSeparator: storeCartState.totals.currency_decimal_separator,
    },
    items: [],
    totalItems: Number(storeCartState.items_count),
    totalUniqueItems: Number(storeCartState.items_count),
    total: Number(storeCartState.totals.total_price),
    isEmpty: Number(storeCartState.items_count) < 1,
    discounts: Number(storeCartState.totals.total_discount),
    deliveryCost: Number(storeCartState.totals.total_shipping),
    taxes: Number(storeCartState.totals.total_tax),
    subtotal: Number(storeCartState.totals.total_items),
  }

  // Parse every item
  // If item is not in backend, it will disappear
  for (const backendItem of storeCartState.items) {
    const cartItem = state.items.find(i => {
      const originalId = i.id.split('.')[0]
      return Number(originalId) === backendItem.id
    })

    if (cartItem) {
      newCartState.items.push(backendItemToCartItem(backendItem, cartItem))
    }
  }

  return newCartState
}

export const backendItemToCartItem = (
  backendItem: StoreCart['items'][0],
  cartItem: FECartItem,
): FECartItem => {
  return {
    ...cartItem,
    itemKey: backendItem.key,
    quantity: backendItem.quantity,
    itemTotal: Number(backendItem.totals.line_total),
    id: cartItem.id,
  }
}
