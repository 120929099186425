import type { AppRouter } from '@gealium/backend/src/router'
import { getTrpc } from '@goatlab/trpc'
import type { inferRouterInputs, inferRouterOutputs } from '@goatlab/trpc'
import Cookies from 'js-cookie'
import { env } from '@src/_env/env'
import { getToken } from '@src/api/http/getToken'
import { firebaseAuthService } from '@src/services/firebase/firebaseAuth.service'

export const { backendRequest, backendHookNext, TRPCProvider } =
  getTrpc<AppRouter>({
    getExistingToken: async () => {
      return getToken() || ''
    },
    getNewToken: async () => {
      return await firebaseAuthService.getNewToken()
    },
    setToken: async (token: string) => {
      Cookies.set('token', token)
    },
    isUserLoggedIn: async () => {
      const isUserLoggedIn = await firebaseAuthService.isUserLoggedIn()
      return !!isUserLoggedIn
    },
    removeToken: async () => {
      return Cookies.remove('token')
    },
    baseApiUrl: env.BACKEND_URL || '',
    showLogs: false,
  })

export const backendHook = backendHookNext

export type RouterOutputs = inferRouterOutputs<AppRouter>

export type RouterInputs = inferRouterInputs<AppRouter>

export type { AppRouter }
