import { EnvironmentProd } from './prod.env'

export class EnvironmentDev extends EnvironmentProd {
  name = 'local'
  prod = false
  dev = true
  local = false
  test = false

  FIREBASE_API_KEY = 'AIzaSyBMSmqu7MRDbb46rg1nL7BOpOwpsXQ3FFo'
  FIREBASE_AUTH_DOMAIN = 'agrosocial-dev.firebaseapp.com'
  FIREBASE_PROJECT_ID = 'agrosocial-dev'
  FIREBASE_APP_ID = '1:356549745978:web:f53916278eeb34e6dd0403'
  FIREBASE_MEASUREMENT_ID = 'G-XLCKFE3G6R'

  BASE_HOST_URL = `https://api-dev.${this.BASE_DOMAIN}`
  BACKEND_URL = this.BASE_HOST_URL
  LINKEDIN_CLIENT_ID = '78wy10ljao5i5a'
  LINKEDIN_REDIRECT_URL = `${this.BACKEND_URL}/linkedin/auth`
  FIREBASE_EMULATOR_URL = ''
  TYPESENSE_URL = `https://9nzo54gywhljsb6vp-1.a1.typesense.net`
  TYPESENSE_TOKEN = 'Qm9yqhAj0mBwWZ461sBGZwgb2spOevDm'
}

export const env = new EnvironmentDev()
