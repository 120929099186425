import { createTRPCReact } from '@trpc/react-query'
import {
  HTTPBatchLinkOptions,
  createTRPCClient,
  httpBatchLink,
  loggerLink,
} from '@trpc/client'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Router } from '@trpc/server/unstable-core-do-not-import'
import { createTRPCNext } from '@trpc/next'
import { parseJwt } from './parseJwt'

type TrpcParams = {
  getExistingToken: () => Promise<string>
  getNewToken: () => Promise<string | undefined>
  setToken: (token: string) => Promise<void>
  removeToken: () => Promise<void>
  isUserLoggedIn: () => Promise<boolean>
  onInvalidTokenDetected: (decodedToken: Record<string, any>) => Promise<void>
  showLogs?: boolean
  baseApiUrl: string
}

export const getTrpc = <T extends Router<any, any>>({
  getExistingToken,
  getNewToken,
  setToken,
  isUserLoggedIn,
  onInvalidTokenDetected,
  removeToken,
  baseApiUrl,
  showLogs,
}: TrpcParams) => {
  /**
   * Configuration for the TRPC client
   */
  const trpcClientSettings: HTTPBatchLinkOptions<any> = {
    url: `${baseApiUrl}/trpc`,
    async headers(): Promise<Record<string, any>> {
      const accessToken = await getExistingToken()

      if (!accessToken) {
        return {}
      }

      const decoded = parseJwt(accessToken)
      const isTokenExpired = decoded.exp < (Date.now() + 1) / 1000

      if (!isTokenExpired) {
        return {
          authorization: `Bearer ${accessToken}`,
        }
      }

      // Token expired and we cant refresh it
      if (!isUserLoggedIn()) {
        onInvalidTokenDetected(decoded)
        return {}
      }

      const newToken = await getNewToken()

      if (!newToken) {
        throw new Error('Could not refresh user Token')
      }
      setToken(newToken)

      return {
        authorization: `Bearer ${newToken}`,
      }
    },
  }

  /**
   * Vanilla new connection each time
   * Can be used outside of React
   */
  const backendRequest = createTRPCClient<T>({
    links: [
      httpBatchLink(trpcClientSettings),
      loggerLink({
        enabled: (opts) =>
          showLogs ||
          (opts.direction === 'down' && opts.result instanceof Error),
        colorMode: 'ansi',
      }),
    ],
  })
  /**
   * TRPC/React-Query hook
   *
   * @returns
   */
  const backendHook = createTRPCReact<T>()

  const backendHookNext = createTRPCNext<T>({
    config() {
      return {
        links: [httpBatchLink(trpcClientSettings)],
      }
    },
    ssr: false,
  })

  const trpcClient = backendHook.createClient({
    links: [httpBatchLink(trpcClientSettings)],
  })

  const queryClient = new QueryClient()
  /**
   * A wrapper for your app that provides the TRPC context.
   * Use only in _app.tsx
   */
  function TRPCProvider(props: { children: React.ReactNode }) {
    return (
      <QueryClientProvider client={queryClient}>
        <backendHook.Provider client={trpcClient} queryClient={queryClient}>
          {props.children}
        </backendHook.Provider>
      </QueryClientProvider>
    )
  }

  return {
    backendRequest,
    backendHook,
    backendHookNext,
    TRPCProvider,
    queryClient,
  }
}
