import { useRouter } from 'next/router'
import { useWindowSize } from '@src/utils/use-window-size'

export interface IsOutput {
  isAccountPage: boolean
  isSpacePage: boolean
  isMobile: boolean
  isTablet: boolean
  isDesktop: boolean
  isLargeDesktop: boolean
}

export const useIs = (): IsOutput => {
  const router = useRouter()
  const { width } = useWindowSize()
  const isAccountPage = router.route.includes('/account/')
  const isSpacePage = !!router.query?.space && router.route.includes('/[space]')

  const isMobile = !!(width < 768)
  const isTablet = !!(width >= 768) && !!(width < 992)
  const isDesktop = !!(width >= 992) && !!(width < 1200)
  const isLargeDesktop = !!(width >= 1200)

  return {
    isAccountPage,
    isSpacePage,
    isMobile,
    isTablet,
    isDesktop,
    isLargeDesktop,
  }
}
