import { create } from 'zustand'
import { MenuItems } from '@src/utils/buildHeaders'

export interface SiteState {
  isSpace: boolean
  isExternalDomain: boolean
  domain: string
  slug: string
  logo: string
  menuItems: MenuItems
  isOrganization: boolean
  accountId: string
  accountName: string
  landingPageHTML: string
  landingPageCSS: string
}

const initialSearchState: SiteState = {
  isExternalDomain: false,
  isSpace: false,
  isOrganization: false,
  domain: '',
  slug: '',
  accountId: '',
  accountName: '',
  logo: '',
  menuItems: [],
  landingPageHTML: '',
  landingPageCSS: '',
}

export interface SiteActions {
  setSite: (site: SiteState) => void
  reset: () => void
}

export const useSite = create<SiteState & SiteActions>(set => {
  return {
    ...initialSearchState,
    setSite(site) {
      set(site)
    },
    reset() {
      set(initialSearchState)
    },
  }
})
