import type { SearchableAccount } from '@gealium/backend/src/services/typesense/collections/account.collection'
import {
  useQuery,
  UseQueryResult,
  useInfiniteQuery,
  UseInfiniteQueryResult,
  UseInfiniteQueryOptions,
  UseQueryOptions,
  usePrefetchQuery,
} from '@tanstack/react-query'
import ky, { KyInstance } from 'ky'
import type {
  PaginatedMultiSearchResult,
  SearchableProduct,
  SearchableCollection,
  TypesenseMultiSearchQuery,
  TypesenseMultiSearchQueryResults,
  TypesenseQuery,
  TypesenseQueryResults,
} from './typesense.model'

export class TypesenseService {
  private typesenseAPI: KyInstance

  constructor({ prefixUrl, token }: { prefixUrl: string; token: string }) {
    this.typesenseAPI = ky.extend({
      prefixUrl,
      timeout: 5000,
      retry: 5,
      headers: {
        'X-TYPESENSE-API-KEY': token,
      },
    })
  }

  async searchAccount(
    query: TypesenseQuery
  ): Promise<TypesenseQueryResults<SearchableAccount>> {
    return await this.typesenseAPI
      .get(`collections/accounts/documents/search`, {
        searchParams: {
          ...query,
        },
      })
      .json<TypesenseQueryResults<SearchableAccount>>()
  }

  async searchAccountById(id: string): Promise<SearchableAccount> {
    const result = await this.typesenseAPI
      .get(`collections/accounts/documents/${id}`)
      .json<SearchableAccount>()

    return result
  }

  async searchProducts(
    query: TypesenseQuery
  ): Promise<TypesenseQueryResults<SearchableProduct>> {
    return await this.typesenseAPI
      .get(`collections/products/documents/search`, {
        searchParams: {
          ...query,
        },
      })
      .json<TypesenseQueryResults<SearchableProduct>>()
  }

  public useSearchAccount = (
    options: TypesenseQuery
  ): UseQueryResult<TypesenseQueryResults<SearchableAccount>, Error> => {
    return useQuery<TypesenseQueryResults<SearchableAccount>, Error>({
      queryKey: [`collections/accounts/documents/search`, options],
      initialData: [] as any,
      queryFn: async () => await this.searchAccount(options),
    })
  }

  public useSearchAccountById = (
    id: string
  ): UseQueryResult<SearchableAccount, Error> => {
    return useQuery<SearchableAccount, Error>({
      queryKey: [`collections/accounts/documents/${id}`, id],
      initialData: [] as any,
      queryFn: async () => await this.searchAccountById(id),
    })
  }

  public preFetchAccountById = (id: string): void => {
    return usePrefetchQuery<SearchableAccount, Error>({
      queryKey: [`collections/accounts/documents/${id}`, id],
      initialData: [] as any,
      queryFn: async () => await this.searchAccountById(id),
    })
  }

  public paginatedMultiSearch = async (
    searches: TypesenseMultiSearchQuery
  ): Promise<PaginatedMultiSearchResult[]> => {
    const filteredSearches = searches.filter(
      (s) => s.page !== null && s.page !== undefined
    )
    const results = await this.multiSearch(filteredSearches)
    const searchResult: PaginatedMultiSearchResult[] = []

    for (const collectionResult of results) {
      const currentPage = collectionResult.page
      const searchLimit = collectionResult.request_params.per_page
      const lastPage = Math.ceil(
        collectionResult.found / collectionResult.request_params.per_page
      )

      const nextPage = currentPage + 1 > lastPage ? null : currentPage + 1
      const prevPage = currentPage - 1 <= 0 ? null : currentPage - 1
      const from =
        (currentPage - 1) * searchLimit <= 0
          ? 1
          : (currentPage - 1) * searchLimit
      const to = currentPage * searchLimit

      searchResult.push({
        results: collectionResult.hits.map((h) => h.document) as any,
        collection: collectionResult.request_params
          .collection_name as SearchableCollection,
        currentPage,
        perPage: searchLimit,
        total: collectionResult.found,
        lastPage,
        firstPage: 1,
        nextPage,
        prevPage,
        from,
        to,
      })
    }

    return searchResult
  }

  async multiSearch(searches: TypesenseMultiSearchQuery) {
    const { results } = await this.typesenseAPI
      .post(`multi_search`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          searches,
        }),
      })
      .json<TypesenseMultiSearchQueryResults>()
    return results
  }

  async getProductBySlug(slug: string) {
    return await this.searchProducts({
      q: slug,
      query_by: 'slug',
    })
  }

  async getSlugByDomain(domain: string) {
    const response = await this.typesenseAPI
      .post(`multi_search?query_by=domains`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          searches: [
            {
              collection: 'staff',
              q: domain,
            },
            {
              collection: 'organizations',
              q: domain,
            },
          ],
        }),
      })
      .json<{ results: TypesenseQueryResults<any>[] }>()

    const result = response.results.find((r) => r.found > 0)
    const slug = result?.hits?.[0]?.document?.slug

    return slug
  }

  async getMainPageBySlug(slug: string): Promise<SearchableAccount | null> {
    const response = await this.typesenseAPI
      .post(`multi_search?query_by=slug`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          searches: [
            {
              collection: 'accounts',
              q: slug,
            },
          ],
        }),
      })
      .json<{
        results: TypesenseQueryResults<SearchableAccount>[]
      }>()

    const result = response.results.find((r) => r.found > 0)
    const doc = result?.hits?.[0]?.document

    if (!doc) {
      return null
    }

    return doc as SearchableAccount
  }

  public useMultiSearch = (
    query: TypesenseMultiSearchQuery,
    options?: Omit<
      UseQueryOptions<any, any>,
      'queryFn' | 'initialData' | 'queryKey'
    >
  ): UseQueryResult<
    TypesenseMultiSearchQueryResults['results'] | null,
    Error
  > => {
    return useQuery<TypesenseMultiSearchQueryResults['results'] | null, Error>({
      queryKey: [`collections/multi_search/${JSON.stringify(query)}`, query],
      initialData: [] as any,
      queryFn: async () => await this.multiSearch(query),
      ...options,
    })
  }

  public useGetMainPageBySlug = (
    slug: string,
    options?: UseQueryOptions<any, any>
  ): UseQueryResult<SearchableAccount | null, Error> => {
    return useQuery<SearchableAccount | null, Error>({
      queryKey: [`multi_search?query_by=slug`, slug],
      initialData: [] as any,
      queryFn: async () => await this.getMainPageBySlug(slug),
      ...options,
    })
  }

  public useInfiniteMultiSearch = (
    multiSearchQuery: TypesenseMultiSearchQuery,
    queryOptions: Omit<
      UseInfiniteQueryOptions<PaginatedMultiSearchResult[]>,
      'queryKey' | 'queryFn' | 'getNextPageParam'
    >
  ): UseInfiniteQueryResult<PaginatedMultiSearchResult[], Error> => {
    return useInfiniteQuery({
      queryKey: [
        `collections/multi_search/documents/search/${JSON.stringify(
          multiSearchQuery
        )}`,
      ],
      queryFn: (param) => {
        const search = multiSearchQuery.map((q, index) => {
          // Needs to define it as any so typescript
          // stops complaining fix with real type
          const pageParam = param.pageParam as any
          q.page = pageParam ? pageParam[index] : 1
          return q
        })
        return this.paginatedMultiSearch(search)
      },
      getNextPageParam(results) {
        const nextPages: (number | null)[] = []

        if (results.flatMap((r) => r.nextPage).every((r) => r === null)) {
          return undefined
        }

        for (const result of results) {
          nextPages.push(result.nextPage)
        }

        return nextPages
      },
      getPreviousPageParam(results) {
        const nextPages: (number | null)[] = []
        for (const result of results) {
          nextPages.push(result.prevPage)
        }

        if (nextPages.every((r) => r === null)) {
          return undefined
        }

        return nextPages
      },
      ...queryOptions,
    })
  }

  public useGetProductBySlug = (
    slug: string,
    options?: Omit<UseQueryOptions<any, any>, 'queryKey'>
  ): UseQueryResult<SearchableProduct | null, Error> => {
    return useQuery<SearchableProduct | null, Error>({
      queryKey: [`collections/staff/documents/search`, options],
      initialData: [] as any,
      queryFn: async () => {
        const results = await this.getProductBySlug(slug)

        return results.hits[0]?.document || null
      },
      ...options,
    })
  }
}
