import { create } from 'zustand'

export interface SearchState {
  q: string
  categories: string[]
  resultsAmount: number
}

const initialSearchState: SearchState = {
  q: '',
  categories: [],
  resultsAmount: 0,
}

export interface SearchActions {
  setResultsAmount: (amount?: number) => void
  setSearchQuery: (q?: string) => void
  setCategories: (c?: string[]) => void
  resetSearch: () => void
}

export const useSearchStore = create<SearchState & SearchActions>(
  (set, get) => {
    return {
      ...initialSearchState,
      setResultsAmount(amount) {
        set({ resultsAmount: amount ?? 0 })
      },
      setSearchQuery(q) {
        const state = get()
        if (!q) {
          if (state.q !== '') {
            return set({ q: '' })
          }

          return
        }
        set({ q })
      },
      setCategories(c) {
        set({ categories: c ?? [] })
      },
      resetSearch() {
        set(initialSearchState)
      },
    }
  }
)
