import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { getDirection } from '@src/utils/get-direction'

export const useHtmlDirection = () => {
  const router = useRouter()
  const dir = getDirection(router.locale)

  useEffect(() => {
    document.documentElement.dir = dir
  }, [dir])

  useEffect(() => {
    const html = document?.querySelector('html')
    if (html) {
      html.style.scrollBehavior = 'auto'
      window.scroll({ top: 0 })
      html.style.scrollBehavior = ''
    }
  }, [router.asPath])
}
